import React, { useContext, useEffect, useState } from "react";
import { Button } from "@chakra-ui/button";
import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import axios from "axios";

import { CORE_API_URL } from "../../config";
import { Layout } from "../components/organisms/layout/layout";
import Sidebar from "../components/organisms/sidebar/sidebar";
import { AppContext } from "../context/app-context";
import { useToast } from "@chakra-ui/toast";
import { DeriveError } from "../utils/derive-error";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Divider } from "../components/atoms/divider/divider";
import { addMonths, format } from "date-fns";
import { AiOutlineCreditCard } from "react-icons/ai";
import { BsCreditCard2Front } from "react-icons/bs";
import { Logs } from "../components/organisms/logs/logs";
import { navigate } from "gatsby-link";
import mixpanel from "mixpanel-browser";
// markup
const Settings = () => {
  React.useEffect(() => {}, []);
  const context = useContext(AppContext);
  const { user, setUser } = context;
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [paymentMethodButtonLoading, setPaymentMethodButtonLoading] =
    useState(false);
  useEffect(() => {
    mixpanel.init("2c699372d52051ce0721290d82e9c671", { debug: true });

    const apiCall = async () => {
      try {
        setLoading(true);
        // query user first
        let response;
        let userCopy = { ...user };
        /* if (!userCopy.notionCredential) {
          response = await axios.post(
            `${CORE_API_URL}/get_refreshed_user`,
            {
              user,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          userCopy = response.data.user;
          setUser(userCopy);
        } */
        console.log(userCopy);
        response = await axios.post(
          `${CORE_API_URL}/get_user_invoices`,
          {
            user: userCopy,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setInvoices(response.data.invoices);
        setUser({ ...user, ...response.data.user });
        setActiveSubscription(response.data.activeSubscription);
        setPaymentMethod(response.data.paymentMethod);
        console.log(response.data);
      } catch (error) {
        const err = DeriveError(error);
        toast({
          title: "Invoice query error.",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        if (error && error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          return;
        } else if (!user) {
          navigate("/sign-in");
        } else if (!user.notionCredential) {
          window.location.href = `${CORE_API_URL}/install?email=${user.email}`;
        }
      } finally {
        setLoading(false);
      }
    };
    apiCall();
  }, []);
  const [open, setOpen] = useState(false);
  if (!user) return <></>;
  console.log(context);
  const renderActiveSubscription = () => {
    return (
      <>
        <Box
          bg="white"
          p="6"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          mt="6"
        >
          <HStack>
            <Text>Billing Period</Text>
            <Badge>
              {format(
                new Date(activeSubscription.current_period_start * 1000),
                "MM/dd/yyyy"
              )}{" "}
            </Badge>
            -
            <Badge>
              {format(
                new Date(activeSubscription.current_period_end * 1000),
                "MM/dd/yyyy"
              )}
            </Badge>
          </HStack>
        </Box>
        <Box
          bg="white"
          p="6"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          mt="6"
        >
          <HStack>
            <Text>Subscription Price</Text>
            <Badge>
              ${(activeSubscription.invoice.amount_due / 100).toFixed(2)}
            </Badge>
          </HStack>
        </Box>
      </>
    );
  };
  return (
    <Layout>
      <Sidebar>
        {loading ? (
          <Stack>
            <Skeleton height="300px" />
            <Skeleton height="300px" />
          </Stack>
        ) : (
          <>
            <Button
              as={"a"}
              href={`https://api.botion.so/install?email=${user.email}`}
              variant={"outline"}
              bg="#fff"
              mb="4"
              onClick={() => {
                mixpanel.track("Clicked reauthorize notion");
              }}
            >
              Reauthorize Notion
            </Button>
            <Box
              bg="white"
              p="6"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              alignItems="flex-start"
            >
              <Stack direction={["column", "column", "row"]}>
                <Box
                  bg="white"
                  p="6"
                  width="100%"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Alert
                    bg="white"
                    status={activeSubscription ? "success" : "warning"}
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <HStack maxWidth="sm">
                      <AlertIcon boxSize="20px" mr={0} />
                      <AlertTitle mt={4} mb={1} fontSize="lg">
                        {activeSubscription
                          ? "Subscription active!"
                          : "No active subscription"}
                      </AlertTitle>
                    </HStack>

                    <AlertDescription maxWidth="sm">
                      {activeSubscription ? (
                        <>{renderActiveSubscription()}</>
                      ) : (
                        "Subscribe now to enable your customers to pay using payment links, branded logos and more."
                      )}
                    </AlertDescription>
                    <br />
                  </Alert>
                </Box>
                <VStack width="100%" height="100%">
                  <Box
                    bg="white"
                    p="6"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    width="100%"
                    height="100%"
                  >
                    <Tooltip
                      placement={"bottom-start"}
                      label={
                        activeSubscription
                          ? "Click to unsubscribe."
                          : "By enabling payment links you will be charged."
                      }
                    >
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="email-alerts" mb="0">
                          {activeSubscription
                            ? "Payment links enabled"
                            : "Enable payment links"}
                        </FormLabel>
                        <Switch
                          isChecked={activeSubscription}
                          onClick={() => {}}
                          onChange={(e) => {
                            mixpanel.track(
                              "Clicked subscribe for payment links button"
                            );

                            if (activeSubscription) {
                              setUnsubscribeModal(true);
                              // show unsubscribe
                            } else {
                              setOpen(true);
                            }
                          }}
                          id="email-alerts"
                        />
                      </FormControl>
                    </Tooltip>
                  </Box>

                  {paymentMethod ? (
                    <Box
                      bg="white"
                      p="6"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      width="100%"
                      height="100%"
                    >
                      <HStack justifyContent="space-between">
                        <Text> Payment Method</Text>
                        <Button
                          onClick={async () => {
                            mixpanel.track(
                              "Clicked edit payment method button"
                            );
                            try {
                              setPaymentMethodButtonLoading(true);
                              const response = await axios.post(
                                `${CORE_API_URL}/edit_payment_method_via_session`,
                                {
                                  user,
                                },

                                {
                                  headers: {
                                    "Content-Type":
                                      "application/x-www-form-urlencoded",
                                  },
                                }
                              );
                              const { session } = response.data;

                              window.location.href = session.url;
                            } catch (error) {
                              const err = DeriveError(error);
                              toast({
                                title: "Edit payment method error.",
                                description: err,
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                              });
                            } finally {
                              setPaymentMethodButtonLoading(false);
                            }
                          }}
                          variant="outline"
                          isLoading={paymentMethodButtonLoading}
                        >
                          Edit Payment Method
                        </Button>
                      </HStack>

                      <HStack>
                        <AiOutlineCreditCard />
                        <Text>**** **** **** {paymentMethod.card.last4}</Text>
                      </HStack>
                    </Box>
                  ) : (
                    <></>
                  )}
                </VStack>
              </Stack>
            </Box>

            <Modal onClose={() => setOpen(false)} isOpen={open} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Enable Botion Premium</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box
                    onClick={() => {}}
                    bg="white"
                    p="6"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    _hover={{
                      cursor: "pointer",
                      bg: "yellow.300",
                      color: "black.500",
                    }}
                    onClick={async () => {
                      mixpanel.track("Clicked yearly subscription");
                      try {
                        setLoading(true);
                        const response = await axios.post(
                          `${CORE_API_URL}/subscribe_user`,
                          {
                            user,
                            subscriptionType: "yearly",
                          },
                          {
                            headers: {
                              "Content-Type":
                                "application/x-www-form-urlencoded",
                            },
                          }
                        );

                        console.log(response);
                        const { user: newU, session } = response.data;
                        setUser(newU);
                        window.location.href = session.url;
                      } catch (error) {
                        const err = DeriveError(error);
                        toast({
                          title: "Subscription error.",
                          description: err,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <Flex alignItems="center">
                      <Heading size="sm"> Annual Subscription</Heading>

                      <Spacer />
                      <Badge
                        fontSize="lg"
                        variant="outline"
                        colorScheme="black"
                      >
                        $100
                      </Badge>
                    </Flex>
                  </Box>
                  <Divider />
                  <Box
                    bg="white"
                    p="6"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    _hover={{
                      cursor: "pointer",
                      bg: "yellow.400",
                      color: "black.500",
                    }}
                    onClick={async () => {
                      mixpanel.track("Clicked monthly subscription");

                      try {
                        setLoading(true);
                        const response = await axios.post(
                          `${CORE_API_URL}/subscribe_user`,
                          {
                            user,
                            subscriptionType: "monthly",
                          },
                          {
                            headers: {
                              "Content-Type":
                                "application/x-www-form-urlencoded",
                            },
                          }
                        );

                        console.log(response);
                        const { user: newU, session } = response.data;
                        setUser(newU);
                        window.location.href = session.url;
                      } catch (error) {
                        const err = DeriveError(error);
                        toast({
                          title: "Subscription error.",
                          description: err,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <Flex alignItems="center">
                      <Heading size="sm"> Monthly Subscription</Heading>
                      <Spacer />
                      <Badge
                        fontSize="lg"
                        variant="outline"
                        colorScheme="black"
                      >
                        {loading ? <Spinner /> : "$14.99"}
                      </Badge>
                    </Flex>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setOpen(false)}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal
              isCentered
              onClose={() => setUnsubscribeModal(false)}
              isOpen={unsubscribeModal}
              motionPreset="slideInBottom"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Cancel Subscription</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  We would love for you to continue to use Botion.
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => setUnsubscribeModal(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={async () => {
                      mixpanel.track("Clicked cancel subscription");

                      try {
                        setLoading(true);
                        const response = await axios.post(
                          `${CORE_API_URL}/cancel_subscription`,
                          {
                            user,
                            activeSubscription,
                          },
                          {
                            headers: {
                              "Content-Type":
                                "application/x-www-form-urlencoded",
                            },
                          }
                        );
                        setActiveSubscription(null);
                        setUnsubscribeModal(false);
                      } catch (error) {
                        const err = DeriveError(error);
                        toast({
                          title: "Cancellation error. Contact us in discord.",
                          description: err,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    Unsubscribe
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/**
             * Logs Section
             */}

            <Logs />

            <Box
              bg="white"
              p="6"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              width="100%"
              height="100%"
              display="grid"
              mt="4"
            >
              <Box className="">
                <Button
                  onClick={() => {
                    mixpanel.track("Clicked logout ");

                    setUser(null);
                    navigate("/");
                  }}
                  className="mt-4"
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Sidebar>
    </Layout>
  );
};

export default Settings;
