import { Box } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { useToast } from "@chakra-ui/toast";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CORE_API_URL } from "../../../../config";
import { AppContext } from "../../../context/app-context";
import { DeriveError } from "../../../utils/derive-error";

export const Logs = () => {
  const [logs, setLogs] = useState([]);
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const apiCall = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${CORE_API_URL}/get_user_logs`,
          {
            user,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setLogs(
          response.data.logs.sort(function compare(a, b) {
            var dateA = new Date(a.createdAt);
            var dateB = new Date(b.createdAt);
            return dateB - dateA;
          })
        );
      } catch (error) {
        const err = DeriveError(error);
        toast({
          title: "Logs query error.",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    apiCall();
  }, []);
  if (loading)
    return (
      <Skeleton
        bg="white"
        p="6"
        borderWidth="1px"
        borderRadius="lg"
        alignItems="flex-start"
        marginTop="1%"
        minHeight="300"
        maxHeight="300"
      />
    );
  return (
    <Box
      bg="white"
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      overflow="scroll"
      alignItems="flex-start"
      marginTop="1%"
      minHeight="300"
      maxHeight="300"
    >
      <Table size="sm" maxHeight="300" overflow="scroll">
        <Thead>
          <Tr>
            <Th>Date Created</Th>
            <Th>Status Code</Th>
            <Th>Message</Th>
          </Tr>
        </Thead>
        <Tbody>
          {logs.map((log) => {
            return (
              <Tr>
                <Td>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                    timeZone: "America/Los_Angeles",
                  }).format(new Date(log.createdAt))}
                </Td>
                <Td>{log.statusCode}</Td>
                <Td>{log.message}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
